var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "verify verify_template_alert" },
        [
          _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.description))
          ]),
          _vm._v(" "),
          _c("woot-button", {
            attrs: {
              icon: "dismiss",
              "color-scheme": "secondary",
              variant: _vm.clear,
              classNames: "verify_close_btn"
            },
            on: { click: _vm.closeVerify }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }