var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row settings--section" }, [
    _c(
      "div",
      { staticClass: "medium-4 small-12 title--section" },
      [
        _c("p", { staticClass: "sub-block-title" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "sub-head" }, [
          _vm._v("\n      " + _vm._s(_vm.subTitle) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.isCommunalInbox
          ? _c("CommunalRestrictCard", { attrs: { inbox: _vm.inbox } })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "medium-6 small-12" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }