var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "conversation-page" },
    [
      _c(
        "chat-list",
        {
          attrs: {
            "conversation-inbox": _vm.inboxId,
            label: _vm.label,
            "team-id": _vm.teamId,
            "conversation-type": _vm.conversationType,
            "folders-id": _vm.foldersId
          },
          on: { "conversation-load": _vm.onConversationLoad }
        },
        [_c("pop-over-search")],
        1
      ),
      _vm._v(" "),
      _c("conversation-box", {
        attrs: {
          "inbox-id": _vm.inboxId,
          "is-contact-panel-open": _vm.isContactPanelOpen
        },
        on: { "contact-panel-toggle": _vm.onToggleContactPanel }
      }),
      _vm._v(" "),
      _c("ContestEventPopup", {
        on: { "update:isOpen": _vm.onUpdateContestModalOpen }
      }),
      _vm._v(" "),
      !_vm.isContestModalOpen
        ? _c(
            "aside",
            [
              _vm.isIndustryPoupOpen
                ? _c(
                    "section",
                    {
                      staticClass:
                        "instruction_popup modal-popup industry-popup modalWidth"
                    },
                    [
                      _c("div", { staticClass: "wrapper_bg" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.onboardingBgs.octopusAnimBg,
                            alt: ""
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "body_wrap" }, [
                        _c("div", { staticClass: "body primary_bg_shade" }, [
                          _c("h1", { staticClass: "page-title banner_title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("INSTRUCTION_POPUP.ONBOARD_LIST.GREET")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content_box" }, [
                            _c("h2", { staticClass: "sub_title_font" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "INSTRUCTION_POPUP.ONBOARD_LIST.PATH_HEADER"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("INSTRUCTION_POPUP.ONBOARD_LIST.INTRO")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "card_video",
                                class: { hidden: _vm.activeOption.videoUrl }
                              },
                              [
                                _c("iframe", {
                                  ref: "demoVideoFrame",
                                  attrs: {
                                    src: _vm.activeOption.videoUrl,
                                    frameborder: "0",
                                    allow:
                                      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                                    allowfullscreen: ""
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "cards_wrapper_industry" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "industry_arrow industry_right_arrow"
                                  },
                                  [
                                    _c("woot-button", {
                                      attrs: {
                                        variant: "smooth",
                                        size: "tiny",
                                        "color-scheme": "primary",
                                        "class-names": "grey-btn",
                                        "is-loading": false,
                                        icon: "previous"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeCardIndex("decrease")
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "cards" },
                                  _vm._l(_vm.demoOptionsInview, function(el) {
                                    return _c(
                                      "div",
                                      {
                                        key: el.constant_name,
                                        staticClass: "card",
                                        class:
                                          el.constant_name ===
                                          _vm.activeOption.constant_name
                                            ? "card_active"
                                            : "",
                                        on: {
                                          click: function($event) {
                                            return _vm.onDemoOptionClick(el)
                                          }
                                        }
                                      },
                                      [
                                        _c("h3", { staticClass: "title" }, [
                                          _vm._v(_vm._s(el.title))
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "card_icon-wraper" },
                                          [
                                            _c("fluent-icon", {
                                              attrs: { icon: el.icon, size: 40 }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(_vm._s(el.description))
                                        ])
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "industry_arrow industry_right_arrow"
                                  },
                                  [
                                    _c("woot-button", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.top",
                                          value: _vm.Next,
                                          expression: "Next",
                                          modifiers: { top: true }
                                        }
                                      ],
                                      attrs: {
                                        variant: "smooth",
                                        size: "tiny",
                                        "color-scheme": "primary",
                                        "class-names": "grey-btn",
                                        "is-loading": false,
                                        icon: "next"
                                      },
                                      on: { click: _vm.changeCardIndex }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "padding-bottom": "10px" } },
                            [
                              _c(
                                "woot-button",
                                {
                                  attrs: {
                                    "button-text": _vm.$t(
                                      "INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP"
                                    ),
                                    isLoading: _vm.isDemoAutomationSkipLoading
                                  },
                                  on: { click: _vm.skipOnboardingPopup }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "woot-button",
                                {
                                  attrs: {
                                    classNames: "modal_btn",
                                    isLoading: _vm.isDemoAutomationLoading
                                  },
                                  on: { click: _vm.onDemoCardContinue }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "INSTRUCTION_POPUP.ONBOARD_LIST.BUTTON_TEXT"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "modal",
                {
                  attrs: {
                    modalMainContainerClass: "instruction_popup",
                    show: _vm.isExistInboxPopupOpen,
                    "on-close": _vm.closeExistInboxPopup
                  },
                  on: {
                    "update:show": function($event) {
                      _vm.isExistInboxPopupOpen = $event
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "column content-box inbox_container_wrapper"
                    },
                    [
                      _c("woot-modal-header", {
                        attrs: {
                          "header-title": _vm.$t(
                            "INSTRUCTION_POPUP.INBOXES.HEADER"
                          ),
                          "header-content": _vm.$t(
                            "INSTRUCTION_POPUP.INBOXES.INTRO"
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "existing_inboxes" }, [
                        _c(
                          "select",
                          {
                            attrs: {
                              name: "selectedInbox",
                              id: "selectedInbox"
                            },
                            on: { change: _vm.createDemoByExistInbox }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "INSTRUCTION_POPUP.INBOXES.DEFAULT_INBOX_SELECT"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.existingInboxs, function(inbox) {
                              return _c(
                                "option",
                                {
                                  key: inbox.id,
                                  domProps: { value: inbox.id }
                                },
                                [_vm._v(_vm._s(inbox.name))]
                              )
                            })
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _vm.isDemoAutomationLoading
                        ? _c(
                            "div",
                            { staticClass: "inbox_loader_wraper" },
                            [_c("Spinner", { attrs: { size: "medium" } })],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.isBotSuccessPopupOpen
                ? _c(
                    "section",
                    { staticClass: "modal-popup instruction_popup" },
                    [
                      _c("div", { staticClass: "modal-container modalWidth" }, [
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "modal--close",
                              on: { click: _vm.closeBotSuccessPopUp }
                            },
                            [_c("fluent-icon", { attrs: { icon: "dismiss" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c("h3", { staticClass: "page-title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "INSTRUCTION_POPUP.SUCCESS_MESSAGE.TITLE"
                                )
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "INSTRUCTION_POPUP.SUCCESS_MESSAGE.MESSAGE"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("WhatsappInboxCreate", {
                attrs: {
                  isStepOneOpen: _vm.isWhatsappQesPopupOpen,
                  onCloseStepOneFn: _vm.closeWhatsappQuesPopup,
                  isStepTwoOpen: _vm.isWhatsappTwoPopupOpen,
                  onCloseStepTwoFn: _vm.closeWhatsappQuotionPopup,
                  isStepThreeOpen: _vm.isWhatsappThreePopupOpen,
                  onCloseStepThreeFn: _vm.closeWhatsappGiftSelect,
                  onSkipFn: _vm.skipWhatsappInboxSetup,
                  selectedCaution: _vm.whatsappCautionYesOrNo,
                  onSelectCautionOptionFn: _vm.onSelectCautionOption
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }