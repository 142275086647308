var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box onboard_container" }, [
    _c("section", { staticClass: "row" }, [
      _c("div", { staticClass: "column content-box" }, [
        !_vm.currentUser.confirmed
          ? _c(
              "section",
              { staticClass: "business_container verify_container" },
              [
                _c("div", { staticClass: "banner_heading" }, [
                  _c("h3", { staticClass: "page-title" }, [
                    _vm._v(_vm._s(_vm.$t("ARRANGE.VERIFY.TITLE")))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("p", { staticStyle: { margin: "0" } }, [
                      _vm._v(_vm._s(_vm.$t("ARRANGE.VERIFY.MSG_PART_1")))
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("b", [
                        _vm._v(_vm._s(_vm.currentUser.user_phone_number))
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("ARRANGE.VERIFY.MSG_PART_2")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticClass: "wp_btn",
                        on: { click: _vm.handleOpenWhatsApp }
                      },
                      [_vm._v(_vm._s(_vm.$t("ARRANGE.VERIFY.WHATSAPP_BTN")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "resend_box" },
                      [
                        _c("p", { staticStyle: { margin: "0" } }, [
                          _vm._v(_vm._s(_vm.$t("ARRANGE.VERIFY.RESEND_TEXT")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "woot-button",
                          {
                            staticClass: "resend_btn",
                            attrs: {
                              classNames: _vm.isResendEnabled ? "" : "btn_mute",
                              disabled: !_vm.isResendEnabled || _vm.isReSending
                            },
                            on: { click: _vm.handleResendLink }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.isResendEnabled
                                    ? _vm.$t("ARRANGE.VERIFY.RESEND_BTN")
                                    : _vm.$t("ARRANGE.VERIFY.RESEND_BTN") +
                                        " (" +
                                        _vm.countdown +
                                        "s)"
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _c("section", { staticClass: "business_container" }, [
              _c("div", { staticClass: "banner_heading" }, [
                _c("h3", { staticClass: "page-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("ARRANGE.BUSINESS_TYPE.TITLE")))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("p", { staticClass: "sub-block-title sub_title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("ARRANGE.BUSINESS_TYPE.CUSTOMER_LEVEL"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("RadioList", {
                    attrs: { options: _vm.businessTypeOprions },
                    on: { change: _vm.onBusinessTypeChange },
                    model: {
                      value: _vm.selectedBysinessTypeIndex,
                      callback: function($$v) {
                        _vm.selectedBysinessTypeIndex = $$v
                      },
                      expression: "selectedBysinessTypeIndex"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("ARRANGE.BUSINESS_TYPE.INDUSTRY_LEVEL"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("multiselect", {
                    attrs: {
                      options: _vm.services,
                      "track-by": "id",
                      label: "industry",
                      disabled: false,
                      multiple: false,
                      "close-on-select": true,
                      "clear-on-select": false,
                      "hide-selected": true,
                      "tag-placeholder": _vm.$t(
                        "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES"
                      ),
                      taggable: true,
                      placeholder: _vm.$t(
                        "INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER"
                      ),
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                      )
                    },
                    model: {
                      value: _vm.selectedService,
                      callback: function($$v) {
                        _vm.selectedService = $$v
                      },
                      expression: "selectedService"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("woot-input", {
                    class: { error: _vm.$v.average_order.$error },
                    attrs: {
                      type: "number",
                      label: _vm.$t("ARRANGE.BUSINESS_TYPE.ORDER_LEVEL"),
                      placeholder: "500",
                      error: _vm.$v.average_order.$error
                        ? _vm.$t("ARRANGE.BUSINESS_TYPE.ORDER_ERR")
                        : ""
                    },
                    on: { blur: _vm.$v.average_order.$touch },
                    model: {
                      value: _vm.average_order,
                      callback: function($$v) {
                        _vm.average_order =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "average_order"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn_wrap" },
                [
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        "color-scheme": "primary",
                        "is-loading": _vm.isLoading,
                        "is-disabled": _vm.isLoading
                      },
                      on: { click: _vm.onBusinessSubmit }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("ARRANGE.BUSINESS_TYPE.BTN")) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }