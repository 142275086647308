var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onClose,
        closeIcon: "chevron-left",
        closeBtnClass: "modal_box_close_btn",
        modalMainContainerClass: "preview_modal"
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal_box preview_template_modal" },
        [
          _c("div", { staticClass: "header" }, [
            _c("h2", { staticClass: "header_title" }, [
              _vm._v(_vm._s(_vm.$t("MESSAGE_MGMT.PREVIEW.TITLE")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template" }, [
            _c("div", { staticClass: "heading" }, [
              _c("aside", [
                _c("p", { staticClass: "shortcut" }, [
                  _vm._v("/" + _vm._s(_vm.cannItem.short_code))
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "title font_14_5" }, [
                  _vm._v(_vm._s(_vm.inbox.name))
                ])
              ]),
              _vm._v(" "),
              _c("aside", [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "status",
                        class: _vm.cannItem.template_status
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.statusList[_vm.cannItem.template_status])
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !["pending"].includes(_vm.cannItem.template_status)
                      ? _c("woot-button", {
                          attrs: {
                            "class-names": "pre_delete",
                            variant: "clear",
                            icon: "delete",
                            "is-disabled": _vm.isDeleting,
                            "is-loading": _vm.isDeleting
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onDeleteClick(_vm.cannItem)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "col_preview preview" }, [
            _c("div", { staticClass: "pre_header" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("MESSAGE_MGMT.ADD.PREVIEW.TITLE")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pre_message_box" }, [
              _c("div", { staticClass: "preview_card" }, [
                _c(
                  "div",
                  { staticClass: "media" },
                  [
                    [
                      _vm.fileType.isImage
                        ? _c("div", { staticClass: "file_view_wrapper" }, [
                            _c("img", {
                              attrs: { src: _vm.filePreviewUrl, alt: "" }
                            })
                          ])
                        : _vm.fileType.isVideo
                        ? _c("div", { staticClass: "file_view_wrapper" }, [
                            _c(
                              "video",
                              { attrs: { controls: "", autoplay: "false" } },
                              [
                                _c("source", {
                                  attrs: {
                                    src: _vm.filePreviewUrl,
                                    type: "video/mp4"
                                  }
                                }),
                                _vm._v(
                                  "\n                                  Your browser does not support the video tag.\n                              "
                                )
                              ]
                            )
                          ])
                        : _vm.fileType.isPDF
                        ? _c("div", { staticClass: "file_view_wrapper" }, [
                            _c(
                              "iframe",
                              {
                                attrs: {
                                  src: _vm.filePreviewUrl,
                                  frameborder: "0",
                                  width: "100%",
                                  height: "100%"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                  This browser does not support PDFs. Please download the PDF to view it.\n                              "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "msg_content" }, [
                  _c("p", [_vm._v(_vm._s(_vm.cannItem.content))]),
                  _vm._v(" "),
                  _c("time", [_vm._v(_vm._s(_vm.getCurrentTime()))])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pre_btns" },
            [
              _c(
                "woot-button",
                {
                  attrs: { "class-names": "pre_close", variant: "clear" },
                  on: { click: _vm.onClose }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("MESSAGE_MGMT.PREVIEW.BTNS.CLOSE")) +
                      "\n          "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("woot-delete-modal", {
            attrs: {
              show: _vm.showDeleteConfirmationPopup,
              "on-close": _vm.closeDeletePopup,
              "on-confirm": _vm.confirmDeletion,
              title: _vm.$t("CANNED_MGMT.DELETE.CONFIRM.TITLE"),
              message: _vm.deleteMessage,
              "confirm-text": _vm.deleteConfirmText,
              "reject-text": _vm.deleteRejectText
            },
            on: {
              "update:show": function($event) {
                _vm.showDeleteConfirmationPopup = $event
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }