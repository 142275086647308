var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns profile--settings" }, [
    !_vm.uiFlags.isFetchingItem
      ? _c("section", [
          _c("div", { staticClass: "small-12 row profile--settings--row" }, [
            _c("div", { staticClass: "columns small-12 medium-12" }, [
              _c(
                "div",
                {
                  staticClass: "logo_container",
                  on: { click: _vm.triggerShopLogoInput }
                },
                [
                  _c("p", { staticClass: "logo_hint" }, [
                    _vm._v("Click to upload your logo")
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "logo",
                    attrs: {
                      src: _vm.newUploadedLogoPreview || _vm.merchant.logo_url,
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    ref: "shopLogoInput",
                    staticStyle: { display: "none" },
                    attrs: { type: "file", accept: "image/*" },
                    on: { change: _vm.handleShopLogoChange }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _vm.merchant.shop_url
              ? _c(
                  "div",
                  { staticClass: "columns small-12 medium-12" },
                  [
                    _c("QRCode", { attrs: { shop_url: _vm.merchant.shop_url } })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "columns small-12 medium-12" }, [
              _c("div", { staticClass: "row label_wrapper" }, [
                _c(
                  "label",
                  {
                    staticClass: "columns small-12 medium-6",
                    class: { error: _vm.$v.name.$error }
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(
                          _vm.$t("CATALOGUE.SETTING.FIELDS.COMPANY_LABLE")
                        ) +
                        "\n                      "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.name,
                          expression: "name"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "CATALOGUE.SETTING.FIELDS.COMPANY_LABLE"
                        )
                      },
                      domProps: { value: _vm.name },
                      on: {
                        blur: _vm.$v.name.$touch,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.name = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.name.$error
                      ? _c("span", { staticClass: "message" }, [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.$t("CATALOGUE.SETTING.FIELDS.COMPANY_ERR")
                              ) +
                              "\n                      "
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("label", { staticClass: "columns small-12 medium-6" }, [
                  _vm._v(
                    "\n                      " +
                      _vm._s(
                        _vm.$t("CATALOGUE.SETTING.FIELDS.DESCRIPTION_LABLE")
                      ) +
                      "\n                      "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.custom_attributes.description,
                        expression: "custom_attributes.description"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "CATALOGUE.SETTING.FIELDS.DESCRIPTION_PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.custom_attributes.description },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.custom_attributes,
                          "description",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "columns small-12 medium-6",
                    class: {
                      error: _vm.$v.custom_attributes.address.line1.$error
                    }
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(
                          _vm.$t("CATALOGUE.SETTING.FIELDS.ADDRESS_1_LABLE")
                        ) +
                        "\n                      "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.custom_attributes.address.line1,
                          expression: "custom_attributes.address.line1"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "CATALOGUE.SETTING.FIELDS.ADDRESS_1_PLACEHOLDER"
                        )
                      },
                      domProps: { value: _vm.custom_attributes.address.line1 },
                      on: {
                        blur: _vm.$v.custom_attributes.address.line1.$touch,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.custom_attributes.address,
                            "line1",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.custom_attributes.address.line1.$error
                      ? _c("span", { staticClass: "message" }, [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.$t("CATALOGUE.SETTING.FIELDS.ADDRESS_1_ERR")
                              ) +
                              "\n                      "
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("label", { staticClass: "columns small-12 medium-6" }, [
                  _vm._v(
                    "\n                      " +
                      _vm._s(
                        _vm.$t("CATALOGUE.SETTING.FIELDS.ADDRESS_2_LABLE")
                      ) +
                      "\n                      "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.custom_attributes.address.line2,
                        expression: "custom_attributes.address.line2"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "CATALOGUE.SETTING.FIELDS.ADDRESS_2_PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.custom_attributes.address.line2 },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.custom_attributes.address,
                          "line2",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "columns small-12 medium-6",
                    class: {
                      error: _vm.$v.custom_attributes.address.city.$error
                    }
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.$t("CATALOGUE.SETTING.FIELDS.CITY_LABLE")) +
                        "\n                      "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.custom_attributes.address.city,
                          expression: "custom_attributes.address.city"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "CATALOGUE.SETTING.FIELDS.CITY_PLACEHOLDER"
                        )
                      },
                      domProps: { value: _vm.custom_attributes.address.city },
                      on: {
                        blur: _vm.$v.custom_attributes.address.city.$touch,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.custom_attributes.address,
                            "city",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.custom_attributes.address.city.$error
                      ? _c("span", { staticClass: "message" }, [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.$t("CATALOGUE.SETTING.FIELDS.CITY_ERR")
                              ) +
                              "\n                      "
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "columns small-12 medium-6",
                    class: {
                      error: _vm.$v.custom_attributes.address.state.$error
                    }
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.$t("CATALOGUE.SETTING.FIELDS.STATE_LABLE")) +
                        "\n                      "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.custom_attributes.address.state,
                          expression: "custom_attributes.address.state"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "CATALOGUE.SETTING.FIELDS.STATE_PLACEHOLDER"
                        )
                      },
                      domProps: { value: _vm.custom_attributes.address.state },
                      on: {
                        blur: _vm.$v.custom_attributes.address.state.$touch,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.custom_attributes.address,
                            "state",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.custom_attributes.address.state.$error
                      ? _c("span", { staticClass: "message" }, [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.$t("CATALOGUE.SETTING.FIELDS.STATE_ERR")
                              ) +
                              "\n                      "
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("label", { staticClass: "columns small-12 medium-6" }, [
                  _vm._v(
                    "\n                      " +
                      _vm._s(
                        _vm.$t("CATALOGUE.SETTING.FIELDS.POSTCODE_LABLE")
                      ) +
                      "\n                      "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.custom_attributes.address.postcode,
                        expression: "custom_attributes.address.postcode"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "CATALOGUE.SETTING.FIELDS.POSTCODE_PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.custom_attributes.address.postcode },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.custom_attributes.address,
                          "postcode",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "columns small-12 medium-6",
                    class: { error: _vm.$v.phone_number.$error }
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(
                          _vm.$t("CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_LABLE")
                        ) +
                        "\n                      "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone_number,
                          expression: "phone_number"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t(
                          "CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_PLACEHOLDER"
                        )
                      },
                      domProps: { value: _vm.phone_number },
                      on: {
                        blur: _vm.$v.phone_number.$touch,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.phone_number = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.phone_number.$error
                      ? _c("span", { staticClass: "message" }, [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.$t(
                                  "CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_ERR"
                                )
                              ) +
                              "\n                      "
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "group_section flex_justfy_between columns small-12 medium-6"
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.$t("CATALOGUE.SETTING.FIELDS.SST_LABLE")) +
                        "\n                      "
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "toggle-button",
                        class: { active: _vm.custom_attributes.sst },
                        attrs: {
                          type: "button",
                          role: "switch",
                          "aria-checked": _vm.custom_attributes.sst.toString()
                        },
                        on: {
                          click: function($event) {
                            _vm.custom_attributes.sst = !_vm.custom_attributes
                              .sst
                          }
                        }
                      },
                      [
                        _c("span", {
                          class: { active: _vm.custom_attributes.sst },
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "group_section flex_justfy_between columns small-12 medium-6"
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(
                          _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.PAYMENT_BEFORE_ORDER_LABLE"
                          )
                        ) +
                        "\n                      "
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "toggle-button",
                        class: {
                          active: _vm.custom_attributes.payment_before_order
                        },
                        attrs: {
                          type: "button",
                          disabled: !_vm.tekkis_hook_configured,
                          role: "switch",
                          "aria-checked": _vm.custom_attributes.payment_before_order.toString()
                        },
                        on: {
                          click: function($event) {
                            _vm.custom_attributes.payment_before_order = !_vm
                              .custom_attributes.payment_before_order
                          }
                        }
                      },
                      [
                        _c("span", {
                          class: {
                            active: _vm.custom_attributes.payment_before_order
                          },
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "group_section flex_justfy_between columns small-12 medium-6"
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                          " +
                          _vm._s(
                            _vm.$t("CATALOGUE.SETTING.FIELDS.GEOLOCATION_LABLE")
                          ) +
                          "\n                          "
                      ),
                      _c(
                        "span",
                        { staticClass: "geo_info" },
                        [
                          _c("span", { staticClass: "tooltiptext" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "CATALOGUE.SETTING.FIELDS.GEOLOCATION_LABLE_INFO"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("fluent-icon", {
                            attrs: { size: "16", icon: "question-circle" }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "toggle-button",
                        class: { active: _vm.custom_attributes.georestrict },
                        attrs: {
                          type: "button",
                          role: "switch",
                          "aria-checked": _vm.custom_attributes.georestrict.toString()
                        },
                        on: { click: _vm.geoRestrictHandler }
                      },
                      [
                        _c("span", {
                          class: { active: _vm.custom_attributes.georestrict },
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "group_section flex_justfy_between columns small-12 medium-6"
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(
                          _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.DELIVERY_ADDRESS_LABLE"
                          )
                        ) +
                        "\n                      "
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "toggle-button",
                        class: { active: _vm.custom_attributes.delivery },
                        attrs: {
                          type: "button",
                          role: "switch",
                          "aria-checked": _vm.custom_attributes.delivery.toString()
                        },
                        on: { click: _vm.deliveryAddressToggle }
                      },
                      [
                        _c("span", {
                          class: { active: _vm.custom_attributes.delivery },
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.showGoogleMap
                ? _c(
                    "div",
                    [
                      _c("GoogleMap", {
                        attrs: {
                          address: _vm.custom_attributes.address,
                          onClose: _vm.closeGoogleMapModal,
                          show: _vm.showGoogleMap
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "columns small-12 medium-12 group_section" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("CATALOGUE.SETTING.FIELDS.BUSINESS_HOUR_LABLE")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      _vm._l(_vm.business_hours, function(
                        business_hour,
                        index
                      ) {
                        return _c("BusinessHour", {
                          key: business_hour,
                          staticClass: "business_hour",
                          attrs: {
                            business_hour: business_hour,
                            business_hours: _vm.business_hours,
                            DAY_NAMES: _vm.DAY_NAMES,
                            index: index,
                            TIME_INTERVAL: _vm.TIME_INTERVAL,
                            removeBusinessHour: _vm.removeBusinessHour
                          }
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "woot-button",
                          {
                            attrs: {
                              variant: "smooth",
                              size: "small",
                              "color-scheme": "primary",
                              icon: "add",
                              "is-disabled": _vm.isAllBusinessHoursTaken
                            },
                            on: { click: _vm.addBusinessHour }
                          },
                          [
                            _vm._v(
                              "\n                          " +
                                _vm._s(
                                  _vm.$t(
                                    "CATALOGUE.SETTING.FIELDS.ADD_BUSINESS_HOUR_BTN"
                                  )
                                ) +
                                "\n                          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("section", [
                _c("div", { staticClass: "notification_header" }, [
                  _c(
                    "h3",
                    { staticClass: "page-title" },
                    [
                      _c("fluent-icon", { attrs: { icon: "alert" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "CATALOGUE.SETTING.NOTIFICATION_MSG.HEADER.TITLE"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "integration--description" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "CATALOGUE.SETTING.NOTIFICATION_MSG.HEADER.CONTENT"
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "notification_inbox_wrap" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "CATALOGUE.SETTING.NOTIFICATION_MSG.INBOX_SELECT_LABEL"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inbox_select_wrapper" },
                    [
                      _c("multiselect", {
                        key: "close_" + _vm.index,
                        staticClass: "small-12",
                        attrs: {
                          options: _vm.inboxes,
                          disabled: _vm.catalog.inbox_id,
                          "track-by": "id",
                          label: "name",
                          multiple: false,
                          "close-on-select": true,
                          "clear-on-select": false,
                          "hide-selected": true,
                          placeholder: "Pick Inbox",
                          "selected-label": "",
                          "select-label": _vm.$t(
                            "FORMS.MULTISELECT.ENTER_TO_SELECT"
                          ),
                          "deselect-label": _vm.$t(
                            "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                          )
                        },
                        model: {
                          value: _vm.selectedInbox,
                          callback: function($$v) {
                            _vm.selectedInbox = $$v
                          },
                          expression: "selectedInbox"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.catalog.communal_inbox
                  ? _c("div", [
                      _c("p", { staticClass: "communal_notify" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("CATALOGUE.SETTING.COMUNNAL_INBOX_NOTIFY")
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                (_vm.selectedInbox && _vm.selectedInbox.id) ||
                _vm.catalog.inbox_id
                  ? _c(
                      "div",
                      [
                        _c("OrderActionMessagesTable", {
                          attrs: {
                            actionMessages: _vm.messages,
                            cols: _vm.cols,
                            toggleMessage: _vm.toggleMessageHandler,
                            toggleMessageEditor: _vm.toggleMessageEditor,
                            onMessageChange: _vm.onMessageChangeHandler
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        "color-scheme": "primary",
                        "is-loading": _vm.isUpdatingSetting,
                        "is-disabled": _vm.isUpdatingSetting
                      },
                      on: { click: _vm.updateCatalogSetting }
                    },
                    [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.$t("GENERAL_SETTINGS.SUBMIT")) +
                          "\n                  "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }