var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onClose,
        closeIcon: "chevron-left",
        closeBtnClass: "modal_box_close_btn",
        modalMainContainerClass: "create_tpl_modal_container"
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("div", { staticClass: "modal_box create_template_modal" }, [
        _c("div", { staticClass: "header" }, [
          _c("h2", { staticClass: "header_title" }, [
            _vm._v(_vm._s(_vm.$t("MESSAGE_MGMT.ADD.TITLE")))
          ])
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "body" }, [
          _c("aside", [
            _c("label", { staticClass: "field font_medium" }, [
              _vm._v(
                "\n                   " +
                  _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.TEMPLATE_NAME_LABEL")) +
                  "\n                   "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.templateName,
                    expression: "templateName",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "font_large",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "MESSAGE_MGMT.ADD.FORM.TEMPLATE_NAME_PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.templateName },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.templateName = $event.target.value.trim()
                    },
                    _vm.$v.templateName.$touch
                  ],
                  blur: [
                    _vm.$v.templateName.$touch,
                    function($event) {
                      return _vm.$forceUpdate()
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _vm.$v.templateName.$error
                ? _c("span", { staticClass: "err_message" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(
                          _vm.$t("MESSAGE_MGMT.ADD.FORM.TEMPLATE_NAME_ERR")
                        ) +
                        "\n                  "
                    )
                  ])
                : _c("span", { staticClass: "hint" }, [
                    _vm._v(
                      _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.TEMPLATE_NAME_HINT"))
                    )
                  ])
            ]),
            _vm._v(" "),
            _c("aside", { staticClass: "field font_medium" }, [
              _vm._v(
                "\n                   " +
                  _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.INBOX_LABEL")) +
                  "\n                  "
              ),
              _c(
                "div",
                { staticClass: "tmp_select_wrapper" },
                [
                  _c("multiselect", {
                    key: "close_" + "index_inbox",
                    staticClass: "small-12",
                    attrs: {
                      options: _vm.inboxListWhatsApp,
                      disabled: false,
                      "track-by": "id",
                      label: "name",
                      multiple: false,
                      "close-on-select": true,
                      "clear-on-select": false,
                      "hide-selected": true,
                      placeholder: _vm.$t(
                        "MESSAGE_MGMT.ADD.FORM.INBOX_PLACEHOLDER"
                      ),
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                      )
                    },
                    model: {
                      value: _vm.selectedInbox,
                      callback: function($$v) {
                        _vm.selectedInbox = $$v
                      },
                      expression: "selectedInbox"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.selectedInbox.$error
                ? _c("span", { staticClass: "err_message" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.INBOX_ERR")) +
                        "\n                  "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("aside", { staticClass: "field font_medium" }, [
              _vm._v(
                "\n                   " +
                  _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.LANG_LABEL")) +
                  "\n                  "
              ),
              _c(
                "div",
                { staticClass: "tmp_select_wrapper" },
                [
                  _c("multiselect", {
                    key: "close_" + "index_lang",
                    staticClass: "small-12",
                    attrs: {
                      options: _vm.LANGUAGES,
                      disabled: false,
                      "track-by": "value",
                      label: "label",
                      multiple: false,
                      "close-on-select": true,
                      "clear-on-select": false,
                      "hide-selected": true,
                      placeholder: _vm.$t(
                        "MESSAGE_MGMT.ADD.FORM.LANG_PLACEHOLDER"
                      ),
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                      )
                    },
                    model: {
                      value: _vm.selectedLanguage,
                      callback: function($$v) {
                        _vm.selectedLanguage = $$v
                      },
                      expression: "selectedLanguage"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.selectedLanguage.$error
                ? _c("span", { staticClass: "err_message" }, [
                    _vm._v(
                      "\n                          " +
                        _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.TYPE_LANG")) +
                        "\n                      "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("aside", { staticClass: "field font_medium" }, [
              _vm._v(
                "\n                   " +
                  _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.TYPE_LABEL")) +
                  "\n                  "
              ),
              _c(
                "div",
                { staticClass: "tmp_select_wrapper" },
                [
                  _c("multiselect", {
                    key: "close_" + "index",
                    staticClass: "small-12",
                    attrs: {
                      options: _vm.TEMPLATE_TYPES,
                      disabled: false,
                      "track-by": "value",
                      label: "label",
                      multiple: false,
                      "close-on-select": true,
                      "clear-on-select": false,
                      "hide-selected": true,
                      placeholder: _vm.$t(
                        "MESSAGE_MGMT.ADD.FORM.TYPE_PLACEHOLDER"
                      ),
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                      )
                    },
                    model: {
                      value: _vm.selectedType,
                      callback: function($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.selectedType.$error
                ? _c("span", { staticClass: "err_message" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.TYPE_ERR")) +
                        "\n                  "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "field font_medium",
                class: { error: _vm.$v.content.$error }
              },
              [
                _vm._v(
                  "\n                   " +
                    _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.CONTENT_LABEL")) +
                    "\n                   "
                ),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.content,
                      expression: "content",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "font_large",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "MESSAGE_MGMT.ADD.FORM.CONTENT_PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.content },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.content = $event.target.value.trim()
                      },
                      _vm.$v.content.$touch
                    ],
                    blur: [
                      _vm.$v.content.$touch,
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.$v.content.$error
                  ? _c("span", { staticClass: "err_message" }, [
                      _vm._v(
                        "\n                      " +
                          _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.CONTENT_ERR")) +
                          "\n                  "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "field font_medium" }, [
              _vm._v(
                "\n                   " +
                  _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.ATTACHMENT_LABEL")) +
                  "\n                  "
              ),
              _c(
                "div",
                { staticClass: "attachment" },
                [
                  _c("RadioList", {
                    attrs: { options: _vm.attachmentList },
                    on: { change: _vm.onAttachTypeSelect },
                    model: {
                      value: _vm.selectedAttachType,
                      callback: function($$v) {
                        _vm.selectedAttachType = $$v
                      },
                      expression: "selectedAttachType"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.selectedAttachRadio === "new_attachement"
                ? _c("div", [
                    _c("input", {
                      ref: "file",
                      staticClass: "font_large file_upload",
                      attrs: {
                        id: "file",
                        type: "file",
                        accept: "image/*, .mp4, .pdf"
                      },
                      on: { change: _vm.handleImageUpload }
                    })
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("aside", { staticClass: "field font_medium" }, [
              _vm._v(
                "\n                   " +
                  _vm._s(_vm.$t("MESSAGE_MGMT.ADD.FORM.ACTION_LABEL")) +
                  "\n                  "
              ),
              _c(
                "div",
                { staticClass: "tmp_select_wrapper" },
                [
                  _c("multiselect", {
                    key: "close_" + "index",
                    staticClass: "small-12",
                    attrs: {
                      options: _vm.actionBtnOptions,
                      disabled: false,
                      "track-by": "code",
                      label: "name",
                      max: 3,
                      multiple: true,
                      taggable: true,
                      "close-on-select": true,
                      "clear-on-select": false,
                      "hide-selected": true,
                      "tag-placeholder": _vm.$t(
                        "TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL"
                      ),
                      placeholder: _vm.$t(
                        "MESSAGE_MGMT.ADD.FORM.ACTION_PLACEHOLDER"
                      ),
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                      )
                    },
                    on: { tag: _vm.addNewButtonAction },
                    model: {
                      value: _vm.selectedActionBtnList,
                      callback: function($$v) {
                        _vm.selectedActionBtnList = $$v
                      },
                      expression: "selectedActionBtnList"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("aside", { staticClass: "col_preview preview" }, [
            _c("div", { staticClass: "pre_header" }, [
              _c("h3", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("MESSAGE_MGMT.ADD.PREVIEW.TITLE")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pre_message_box" }, [
              _c("div", { staticClass: "preview_card" }, [
                _c(
                  "div",
                  { staticClass: "media" },
                  [
                    [
                      _vm.fileType.isImage
                        ? _c("div", { staticClass: "file_view_wrapper" }, [
                            _c("img", {
                              attrs: { src: _vm.filePreviewUrl, alt: "" }
                            })
                          ])
                        : _vm.fileType.isVideo
                        ? _c("div", { staticClass: "file_view_wrapper" }, [
                            _c(
                              "video",
                              { attrs: { controls: "", autoplay: "false" } },
                              [
                                _c("source", {
                                  attrs: {
                                    src: _vm.filePreviewUrl,
                                    type: "video/mp4"
                                  }
                                }),
                                _vm._v(
                                  "\n                                      Your browser does not support the video tag.\n                                  "
                                )
                              ]
                            )
                          ])
                        : _vm.fileType.isPDF
                        ? _c("div", { staticClass: "file_view_wrapper" }, [
                            _c(
                              "iframe",
                              {
                                attrs: {
                                  src: _vm.filePreviewUrl,
                                  frameborder: "0",
                                  width: "100%",
                                  height: "100%"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                      This browser does not support PDFs. Please download the PDF to view it.\n                                  "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _vm.content
                    ? _c("p", [_vm._v(_vm._s(_vm.content))])
                    : _c("p", { staticClass: "placeholder" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "MESSAGE_MGMT.ADD.PREVIEW.CONTENT_PLACEHOLDER"
                            )
                          )
                        )
                      ]),
                  _vm._v(" "),
                  _c("time", [_vm._v(_vm._s(_vm.getCurrentTime()))])
                ]),
                _vm._v(" "),
                _vm.selectedActionBtnList.length
                  ? _c("div", { staticClass: "buttons" }, [
                      _c(
                        "div",
                        { staticClass: "btn_wrapper" },
                        _vm._l(_vm.selectedActionBtnList, function(buttonEl) {
                          return _c("a", { key: buttonEl.code }, [
                            _vm._v(
                              "\n                              " +
                                _vm._s(buttonEl.name) +
                                "\n                              "
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row submit_btns" }, [
          _c(
            "aside",
            { staticClass: "medium-6 columns" },
            [
              _c(
                "woot-button",
                {
                  attrs: {
                    "class-names": "tmp_submit",
                    isDisabled: _vm.isLoading,
                    isLoading: _vm.isLoading
                  },
                  on: { click: _vm.addTemplateMessage }
                },
                [
                  _vm._v(
                    "\n                  " +
                      _vm._s(_vm.$t("MESSAGE_MGMT.ADD.BTNS.SUBMIT")) +
                      "\n              "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: { "class-names": "tmp_submit", variant: "clear" },
                  on: { click: _vm.onClose }
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("MESSAGE_MGMT.ADD.BTNS.CANCEL")) +
                      "\n              "
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }