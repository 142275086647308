var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onClose,
        modalMainContainerClass: "notify_modal",
        closeBtnClass: "modal_box_close_btn"
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("div", { staticClass: "notify_body" }, [
        _c("aside", { staticClass: "logo" }, [
          _c("img", { attrs: { src: _vm.clockIcon, alt: "" } })
        ]),
        _vm._v(" "),
        _c(
          "aside",
          [
            _c("h3", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("MESSAGE_MGMT.NOTIFY.TITLE")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "description" }, [
              _vm._v(_vm._s(_vm.$t("MESSAGE_MGMT.NOTIFY.DESCRIPTION")))
            ]),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                staticClass: "okay_btn",
                on: {
                  click: function($event) {
                    return _vm.onOkay()
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("MESSAGE_MGMT.NOTIFY.BTN")) +
                    "\n          "
                )
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }