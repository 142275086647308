<template>
  <div class="column content-box onboard_container">

    <!-- ifrme document show for  Pemangkin plan_id=28 -->
    <section class="row">
      <div class="column content-box">
        <div class="banner_heading">
            <h3 class="page-title">{{$t('ONBOARDING_HELP.TITLE')}}</h3>
            <p><b>{{$t('ONBOARDING_HELP.BODY_TEXT_PEMANGKIN')}}</b></p>
        </div>

        <div style="width:100%; overflow:hidden;">
          <div style="width:130%;">
            <div class="pemangkin_doc">
              <iframe src="https://peasy.ai/docs/pemangkinstart/?lang=ms" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- schedule a onboard meeting  -->
    <!--
    <section class="row">
      <div class="column content-box">
        <div class="banner_heading">
            <h3 class="page-title">{{$t('ONBOARDING_HELP.TITLE')}}</h3>
            <p><b>{{$t('ONBOARDING_HELP.BODY_TEXT')}}</b></p>
        </div>
        <div class="banner">
          <div class="banner_img">
            <div class="wrap">
              <img :src="babberLeftImg" alt="">
            </div>
          </div>
          <div>
            <div class="video_container">
              <iframe
                width="560" height="315"
                :src="activeVideo.src"
                :title="activeVideo.title"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                autoplay
              ></iframe>
            </div>
          </div>
          <div></div>
        </div>
        <div class="banner_footer">
          <div class="btns">
            <woot-button classNames="modal_btn" @click="switchVideoHandle">
              {{$t('ONBOARDING_HELP.BANNER.BTNS.JOIN')}}
            </woot-button>
            <woot-button classNames="modal_btn">
              <a style="color:#fff;" href="https://peasy.ai/onboarding-calendar" target="_blank"> {{$t('ONBOARDING_HELP.BANNER.BTNS.SCHEDULE')}}</a>
            </woot-button>
            <woot-button classNames="modal_btn" @click="handleOwnSetup">
              {{$t('ONBOARDING_HELP.BANNER.BTNS.SETUP')}}
            </woot-button>
          </div>
        </div>
      </div>
    </section>
    -->

    <ContestEventPopup />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';
import endPoints from '../../../api/endPoints';
// import AgentApi from "../../../api/agents";
import AssignedAgents from "../../../api/agentAssigned";
import UserAttributeAPI from "../../../api/userAttributes";
import { DEFAULT_REDIRECT_URL } from '../../../constants';
import ContestEventPopup from "../../../../dashboard/components/layout/sidebarComponents/popups/ContestEventPopup.vue"

export default {
    components: {
    ContestEventPopup ,
    },
    // props:{},
    data() {
    return {
            isBookSessionLoading: false,
            isSessionSkipping: false,
            videoList:[
              {
                title:"Start generating sales with Peasy",
                src: "https://www.youtube.com/embed/hre1n81elOE?si=BG3fXKEM3OvUwgcJ&autoplay=1",
              },
              {
                title:"Join our onboarding classes",
                src: "https://www.youtube.com/embed/JjqvxBajBhY?si=hIt3TfeXQYINHfA1&autoplay=1",
              },
            ],
            activeVideo: {},
            isContestLoading: false,
            isContestOpen: false,
            contest: {
                poster_url: "",
                landing_page_url: ""
            },
        };
    },
    computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      ifAppsumoUser:'ifAppsumoUser',
    }),
    babberLeftImg(){
      const leftImg = require('dashboard/assets/images/banners/Octopus.png');
      return leftImg
    },
  },
  mounted() {
    this.activeVideo = this.videoList[0];
    // this.checkWhatsappQuestionPopupOpen();
    this.getRecentContest();
  },
  beforeMount() {
    this.checkIsOnboardingAllowed();
  },
  methods: {
    async checkIsOnboardingAllowed(){
        const user =  Cookies.getJSON('user') || {};
        console.log("checking Onbading user",user);
        if(user.onboard_limit && user.onboard_limit > 0 && !user.appsumo){
            // console.log("set the onboard invitation date");
            this.saveAtPeasySdk(user);
        }else{
            // redirect to home page
            this.$router.push({name:'home'})
        }
    },
    async bookSessionHandler(){
      try {
        this.isBookSessionLoading = true;
        const user = this.currentUser;
        // const {data:agent} = await axios.get(`/api/v1/accounts/${user.account_id}/assigned_agent`);
        const {data:agent} = await AssignedAgents.getAssignedAgent();
        if (agent.id) {
        //   const url = `https://schedule.peasy.ai/book/user/${agent.id}/onboarding`;
          const url = endPoints('onboardingSession').url(agent.id);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank";
          anchor.click();
        }else if (agent.message) {
        //   this.showAlert(agent.message);
        }
      } catch (error) {
        console.log(error);
        // this.showAlert(error.message);
      } finally{
        this.isBookSessionLoading = false;
      }
    },
    async saveAtPeasySdk(user){
    //   const user =  Cookies.getJSON('user') || {};
      window.$peasy.setUser(user.email,{
        name: user.name,
        email: user.email,
      })
      await this.delay(2000);
      window.$peasy.setCustomAttributes({
        invite_date: new Date().toISOString().split('T')[0],
      });
      console.log("saved invite_date at setCustomAttributes = ",window.$peasy.user);
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async skipSessionHandler(){
      try {
        // update skip property in user.custom_attributes.skip = true;
        console.log("Call to update user.custom_attributes.skip");
        const {data} = await UserAttributeAPI.updateCustomAttribute('skip_onboard',true);
        console.log(data);
        this.$router.push(`/app/accounts/${this.currentUser.account_id}/dashboard`);
        // window.location = DEFAULT_REDIRECT_URL;
      } catch (error) {

      }
    },
    switchVideoHandle(){
      this.activeVideo = this.videoList[1];
    },
    handleOwnSetup(){
      // instruct_demo_meeting_event
      console.log("navigate to conversation and open the lead popup ");
      this.$router.push({path: `/app/accounts/${this.currentUser.account_id}/dashboard?isOnboardingOpen=true`});
    },
    async getRecentContest(){
      try {
        this.isContestLoading = true;
        const {data:contest} = await axios.get(endPoints('contestEvent').url());
        console.log(contest);
        if (contest?.poster_url) {
          this.contest = contest;
          this.isContestOpen = true;
        }
      } catch (error) {
        console.log(error);
      }finally{
        this.isContestLoading = false;
      }
    },
    // end methods
  },
}
</script>

<style lang="scss" scoped>
// (navbar height) + (setting header height)

.onboard_container{
  --navbar_height: 60px;
  --setting_header_height: 5.6rem;
  --bg_onboard_color: #ffffff, #f3efff, #eadfff, #e2cefe, #dcbdfb;

  min-height: calc(100vh - (var(--navbar_height) + var(--setting_header_height)));
  background-image: -moz-linear-gradient(to right top, var(--bg_onboard_color));
  background-image: -webkit-linear-gradient(to right top, var(--bg_onboard_color));
  background-image: linear-gradient(to right top, var(--bg_onboard_color));
}
.banner_heading{
  text-align: center;
}

.banner{
  display: grid;
  grid-template-columns: 1fr;
  width: fit-content;
  margin: auto;
  margin-top: 1.8rem;

  .banner_img{
    display: flex;
    align-items: flex-end;

    .warp{
      display: flex;
      align-items: flex-end;
    }
  }

  .video_container{
    margin: auto;
    width: fit-content;

    iframe{
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) {
    .banner{
      grid-template-columns: 200px 1fr;
    }
}
@media only screen and (min-width: 992px) {
    .banner{
      grid-template-columns: 200px 1fr 200px;
    }
}

.banner_footer{
    width: fit-content;
    margin: auto;

  .btns{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
}

.pemangkin_doc{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  iframe{
    position: absolute;
    top: -61%;
    left: -28%;
    width: 100%;
    height: 200%;
  }
}




// @media only screen and (min-width: 420px) {
//   .pemangkin_doc {
//     padding-bottom: 80%;
//     iframe {
//       top: -82%;
//       left: -15%;
//       height: 180%;
//     }
//   }
// }

@media only screen and (min-width: 768px) and (max-width: 819px) {
  .pemangkin_doc {
    padding-bottom: 99%;
    iframe {
      top: -64%;
      left: -27%;
      height: 209%;
    }
  }
}

@media only screen and (min-width: 819px) and (max-width: 899px) {
  .pemangkin_doc {
    padding-bottom: 96%;
  }
    iframe {
      top: -62%;
      left: -25%;
      height: 180%;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1023px) {
  .pemangkin_doc {
    padding-bottom: 85%;
  }
    iframe {
      top: -62%;
      left: -25%;
      height: 180%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .pemangkin_doc {
    padding-bottom: 80%;
    iframe {
      top: -63%;
      left: -25%;
      height: 180%;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1399px) {
  .pemangkin_doc {
    padding-bottom: 65%;
    iframe {
      top: -71%;
      left: -26%;
      height: 220%;
    }
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .pemangkin_doc {
    padding-bottom: 62.25%;
    iframe {
      top: -61%;
      left: -28%;
      height: 200%;
    }
  }
}

// extra screens responsive

@media only screen and (min-width: 1600px) and (max-width: 1799px) {
  .pemangkin_doc {
    padding-bottom: 50%;
  }
  iframe {
    top: -60%;
    left: -30%;
    height: 220%;
  }
}
@media only screen and (min-width: 1800px) and (max-width: 2000px) {
  .pemangkin_doc {
    padding-bottom: 50%;
  }
  iframe {
    top: -60%;
    left: -30%;
    height: 220%;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2500px) {
  .pemangkin_doc {
    padding-bottom: 45%;
  }
  iframe {
    top: -58%;
    left: -32%;
    height: 240%;
  }
}

@media only screen and (min-width: 2500px) and (max-width: 3000px) {
  .pemangkin_doc {
    padding-bottom: 40%;
  }
  iframe {
    top: -55%;
    left: -34%;
    height: 260%;
  }
}

@media only screen and (min-width: 3000px) and (max-width: 3500px) {
  .pemangkin_doc {
    padding-bottom: 35%;
  }
  iframe {
    top: -52%;
    left: -36%;
    height: 280%;
  }
}

@media only screen and (min-width: 3500px) and (max-width: 4000px) {
  .pemangkin_doc {
    padding-bottom: 30%;
  }
  iframe {
    top: -50%;
    left: -38%;
    height: 300%;
  }
}

@media only screen and (min-width: 4000px) and (max-width: 5000px) {
  .pemangkin_doc {
    padding-bottom: 25%;
  }
  iframe {
    top: -48%;
    left: -40%;
    height: 320%;
  }
}

@media only screen and (min-width: 5000px) {
  .pemangkin_doc {
    padding-bottom: 20%;
  }
  iframe {
    top: -45%;
    left: -42%;
    height: 340%;
  }
}


/*
.onboarding{
    text-align: center;
}
.onboarding_btn{
    display: inline-block;
    margin: 10px;
}
only screen and (min-width: 480px)
only screen and (min-width: 768px)
only screen and (min-width: 992px)
only screen and (min-width: 1280px)
*/


</style>
