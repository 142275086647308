var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column padding-top" },
    [
      _vm.filterItemsList
        ? _c("report-filter-selector", {
            attrs: {
              "filter-items-list": _vm.filterItemsList,
              "hide-groupby": false
            },
            on: { "date-range-change": _vm.onDateRangeChange }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row csat--metrics-container" },
        _vm._l(_vm.metrics, function(metric) {
          return _c("csat-metric-card", {
            key: metric.NAME,
            attrs: {
              label: metric.NAME,
              "info-text": metric.NAME,
              value: metric.KEY,
              top_up_expired: _vm.top_up_expired,
              showTopupMsg: metric.showTopupMsg
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "report-bar", staticStyle: { width: "100%" } },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("h4", [_vm._v("Usage Breakdown")]),
                  _vm._v(" "),
                  _c("usage-table", {
                    attrs: {
                      "is-loading": _vm.isFetching,
                      "billing-report": _vm.billingReport
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("h4", [_vm._v("Top-up History")]),
                  _vm._v(" "),
                  _c("topup-history-table", {
                    attrs: {
                      "is-loading": _vm.isFetching,
                      "topup-history": _vm.topupHistory
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }