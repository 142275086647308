import { render, staticRenderFns } from "./WrapperCredit.vue?vue&type=template&id=dce4591c&"
import script from "./WrapperCredit.vue?vue&type=script&lang=js&"
export * from "./WrapperCredit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dce4591c')) {
      api.createRecord('dce4591c', component.options)
    } else {
      api.reload('dce4591c', component.options)
    }
    module.hot.accept("./WrapperCredit.vue?vue&type=template&id=dce4591c&", function () {
      api.rerender('dce4591c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/credit_balance/WrapperCredit.vue"
export default component.exports