var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOnlyQR
    ? _c("div", { staticClass: "qr_code_card" }, [
        _c("div", { staticClass: "qr_code" }, [
          _c("img", {
            attrs: {
              src: _vm.generateQRCode({
                text: _vm.shop_url,
                sizeMultiplier: 5
              }),
              alt: "QR Code"
            }
          })
        ])
      ])
    : _c("div", { staticClass: "columns small-12 medium-12 qr_code_card" }, [
        _c("h2", { staticClass: "page-title" }, [
          _vm._v("👋" + _vm._s(_vm.$t("CATALOGUE.SETTING.QR_CODE.TITLE")))
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("CATALOGUE.SETTING.QR_CODE.CONTENT")))]),
        _vm._v(" "),
        _c("div", { staticClass: "qr_code" }, [
          _c("img", {
            attrs: {
              src: _vm.generateQRCode({
                text: _vm.shop_url,
                sizeMultiplier: 5
              }),
              alt: "QR Code"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(_vm._s(_vm.$t("CATALOGUE.SETTING.QR_CODE.INFO_CONTENT")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "shop_btn" },
          [
            _c("woot-button", { attrs: { "color-scheme": "primary" } }, [
              _c("a", { attrs: { href: _vm.shop_url, target: "_blank" } }, [
                _vm._v(_vm._s(_vm.$t("CATALOGUE.SETTING.QR_CODE.BTN")))
              ])
            ])
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }