var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message_container" },
    [
      _c("div", [
        _vm.cannType === "template"
          ? _c(
              "div",
              { staticClass: "tmp_select_wrapper" },
              [
                _c("multiselect", {
                  key: "close_" + "index",
                  staticClass: "small-12",
                  attrs: {
                    options: _vm.inboxDropdownList,
                    disabled: false,
                    "track-by": "id",
                    label: "name",
                    multiple: false,
                    "close-on-select": true,
                    "clear-on-select": false,
                    "hide-selected": true,
                    placeholder: _vm.$t(
                      "MESSAGE_MGMT.ADD.FORM.INBOX_PLACEHOLDER"
                    ),
                    "selected-label": "",
                    "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                    "deselect-label": _vm.$t(
                      "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                    )
                  },
                  model: {
                    value: _vm.selectedInbox,
                    callback: function($$v) {
                      _vm.selectedInbox = $$v
                    },
                    expression: "selectedInbox"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "section",
        _vm._l(_vm.filteredCanned, function(cann) {
          return _c(
            "div",
            {
              key: cann.id,
              staticClass: "template",
              class: {
                disable_template: ["rejected", "pending"].includes(
                  cann.template_status
                )
              },
              on: {
                click: function($event) {
                  return _vm.onPreviewClick(cann)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "heading" },
                [
                  _c("aside", [
                    _c("p", { staticClass: "shortcut" }, [
                      _vm._v("/" + _vm._s(cann.short_code))
                    ]),
                    _vm._v(" "),
                    _c("h4", { staticClass: "title" }, [
                      _vm._v(_vm._s(cann.inbox && cann.inbox.name))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("aside", [
                    _c("div", [
                      _c(
                        "button",
                        { staticClass: "status", class: cann.template_status },
                        [_vm._v(_vm._s(_vm.statusList[cann.template_status]))]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  !["pending"].includes(cann.template_status)
                    ? _c("woot-button", {
                        attrs: {
                          "class-names": "pre_delete",
                          variant: "clear",
                          icon: "delete",
                          "is-disabled": _vm.isDeleting,
                          "is-loading": _vm.isDeleting
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.onDeleteClick(cann)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "message" }, [
                _vm._v(_vm._s(_vm.truncateText(cann.content, 350)))
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.isCannEmpty
        ? _c(
            "div",
            { staticClass: "empty_box" },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("MESSAGE_MGMT.EMPTY_LIST.MESSAGE")))
              ]),
              _vm._v(" "),
              _vm.cannType === "template"
                ? _c(
                    "woot-button",
                    {
                      staticClass: "clear",
                      attrs: {
                        "class-names": "create_btn",
                        "color-scheme": "primary",
                        variant: "clear"
                      },
                      on: { click: _vm.onShowTemplateModal }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("MESSAGE_MGMT.HEADER_BTN_TXT")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.cannType === "canned"
                ? _c(
                    "woot-button",
                    {
                      staticClass: "clear",
                      attrs: {
                        "class-names": "create_btn",
                        "color-scheme": "primary",
                        variant: "clear"
                      },
                      on: { click: _vm.onShowAddCannModal }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("CANNED_MGMT.HEADER_BTN_TXT")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("PreviewTemplateModal", {
        attrs: {
          show: _vm.previewMessageShow,
          onClose: _vm.onClosePreviewModal,
          cannItem: _vm.previewMessage
        }
      }),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("CANNED_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.deleteMessage,
          "confirm-text": _vm.deleteConfirmText,
          "reject-text": _vm.deleteRejectText
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }