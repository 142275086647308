var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "communal_QR" },
    [
      _vm.currentUser && _vm.inbox
        ? _c("QRCode", { attrs: { isOnlyQR: true, shop_url: _vm.whatsappUrl } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info" },
        [
          _c("p", { staticClass: "font_medium" }, [
            _vm._v(
              _vm._s(_vm.$t("COMMUNAL_WP_SETTING.WHATSAPP_NUMBER")) +
                " " +
                _vm._s(_vm.inbox.phone_number)
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "font_medium" }, [
            _vm._v(
              _vm._s(_vm.$t("COMMUNAL_WP_SETTING.UNIQUE_CODE")) +
                _vm._s(_vm.currentUser.account_id)
            )
          ]),
          _vm._v(" "),
          _c("woot-button", { on: { click: _vm.copyWhatsappLink } }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("COMMUNAL_WP_SETTING.SHARE_WHATSAPP_LINK")) +
                "\n      "
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "notes_box" }, [
        _c("p", { staticClass: "font_nomal" }, [
          _vm._v(_vm._s(_vm.$t("COMMUNAL_WP_SETTING.NOTE_LABEL")))
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "font_nomal alpha_list" }, [
          _c("li", [
            _vm._v(_vm._s(_vm.$t("COMMUNAL_WP_SETTING.NOTES.NOTE_1")))
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(_vm._s(_vm.$t("COMMUNAL_WP_SETTING.NOTES.NOTE_2")))
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "\n              " +
                _vm._s(_vm.$t("COMMUNAL_WP_SETTING.NOTES.NOTE_3_1")) +
                _vm._s(_vm.currentUser.account_id) +
                "\n              " +
                _vm._s(_vm.$t("COMMUNAL_WP_SETTING.NOTES.NOTE_3_2")) +
                "\n          "
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }