var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resolve-actions" }, [
    _c(
      "div",
      { staticClass: "button-group" },
      [
        _vm.isOpen
          ? _c(
              "woot-button",
              {
                attrs: {
                  "class-names": "resolve resolve_checkmark",
                  icon: "checkmark",
                  emoji: "✅",
                  "is-loading": _vm.isLoading
                },
                on: { click: _vm.onCmdResolveConversation }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(this.$t("CONVERSATION.HEADER.RESOLVE_ACTION")) +
                    "\n    "
                )
              ]
            )
          : _vm.isResolved
          ? _c(
              "woot-button",
              {
                attrs: {
                  "class-names": "resolve",
                  "color-scheme": "warning",
                  icon: "arrow-redo",
                  emoji: "👀",
                  "is-loading": _vm.isLoading
                },
                on: { click: _vm.onCmdOpenConversation }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(this.$t("CONVERSATION.HEADER.REOPEN_ACTION")) +
                    "\n    "
                )
              ]
            )
          : _vm.showOpenButton
          ? _c(
              "woot-button",
              {
                attrs: {
                  "class-names": "resolve",
                  "color-scheme": "primary",
                  icon: "person",
                  "is-loading": _vm.isLoading
                },
                on: { click: _vm.onCmdOpenConversation }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(this.$t("CONVERSATION.HEADER.OPEN_ACTION")) +
                    "\n    "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showAdditionalActions
          ? _c("woot-button", {
              ref: "arrowDownButton",
              attrs: {
                disabled: _vm.isLoading,
                icon: "chevron-down",
                emoji: "🔽"
              },
              on: { click: _vm.openDropdown }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.showActionsDropdown
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.closeDropdown,
                expression: "closeDropdown"
              }
            ],
            staticClass: "dropdown-pane dropdown-pane--open"
          },
          [
            _c(
              "woot-dropdown-menu",
              [
                !_vm.isPending
                  ? _c(
                      "woot-dropdown-item",
                      [
                        _c(
                          "woot-button",
                          {
                            attrs: {
                              variant: "clear",
                              "color-scheme": "secondary",
                              size: "small",
                              icon: "book-clock"
                            },
                            on: {
                              click: function() {
                                return _vm.toggleStatus(_vm.STATUS_TYPE.PENDING)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  this.$t(
                                    "CONVERSATION.RESOLVE_DROPDOWN.MARK_PENDING"
                                  )
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isOpen ? _c("woot-dropdown-divider") : _vm._e(),
                _vm._v(" "),
                _vm.isOpen
                  ? _c(
                      "woot-dropdown-sub-menu",
                      {
                        attrs: {
                          title: this.$t(
                            "CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.TITLE"
                          )
                        }
                      },
                      [
                        _c(
                          "woot-dropdown-item",
                          [
                            _c(
                              "woot-button",
                              {
                                attrs: {
                                  variant: "clear",
                                  "color-scheme": "secondary",
                                  size: "small",
                                  icon: "send-clock"
                                },
                                on: {
                                  click: function() {
                                    return _vm.toggleStatus(
                                      _vm.STATUS_TYPE.SNOOZED,
                                      null
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      this.$t(
                                        "CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.NEXT_REPLY"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "woot-dropdown-item",
                          [
                            _c(
                              "woot-button",
                              {
                                attrs: {
                                  variant: "clear",
                                  "color-scheme": "secondary",
                                  size: "small",
                                  icon: "dual-screen-clock"
                                },
                                on: {
                                  click: function() {
                                    return _vm.toggleStatus(
                                      _vm.STATUS_TYPE.SNOOZED,
                                      _vm.snoozeTimes.tomorrow
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      this.$t(
                                        "CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.TOMORROW"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "woot-dropdown-item",
                          [
                            _c(
                              "woot-button",
                              {
                                attrs: {
                                  variant: "clear",
                                  "color-scheme": "secondary",
                                  size: "small",
                                  icon: "calendar-clock"
                                },
                                on: {
                                  click: function() {
                                    return _vm.toggleStatus(
                                      _vm.STATUS_TYPE.SNOOZED,
                                      _vm.snoozeTimes.nextWeek
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      this.$t(
                                        "CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.NEXT_WEEK"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.isCustomDateOpen
                          ? _c("woot-dropdown-item", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "button clear small secondary nice",
                                  on: {
                                    mousedown: function() {
                                      _vm.isCustomDateOpen = !_vm.isCustomDateOpen
                                    }
                                  }
                                },
                                [
                                  _c("fluent-icon", {
                                    staticClass: "icon customImgIcon ",
                                    attrs: { icon: "book-clock" }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "button__content" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          this.$t(
                                            "CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.CUSTOM_TIME"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isCustomDateOpen
                          ? _c("woot-dropdown-item", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "button clear small secondary customBtn"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        this.$t(
                                          "CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.CUSTOM_TIME"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "customBtn-timePick" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "customTimeBtn button__content",
                                          on: {
                                            click: function() {
                                              return _vm.onCustomDateConfirm(
                                                _vm.STATUS_TYPE.SNOOZED,
                                                _vm.snoozeTimes.customTime
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Confirm")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass:
                                          "custom-snoozeTime button__content",
                                        attrs: {
                                          type: "datetime-local",
                                          name: "",
                                          min: _vm.minSnoozeDate,
                                          id: ""
                                        },
                                        on: { change: _vm.onCustomDateChange }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }