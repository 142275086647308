var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box messages_wrapper" },
    [
      _c(
        "div",
        { staticClass: "top-right" },
        [
          _vm.activeTab === "template"
            ? _c(
                "woot-button",
                {
                  attrs: {
                    "color-scheme": "success",
                    "class-names": "button--template",
                    icon: "add-circle"
                  },
                  on: { click: _vm.onShowTemplateModal }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("MESSAGE_MGMT.HEADER_BTN_TXT")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeTab === "canned"
            ? _c(
                "woot-button",
                {
                  attrs: {
                    "color-scheme": "success",
                    "class-names": "button--template",
                    icon: "add-circle"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openAddPopup()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CANNED_MGMT.HEADER_BTN_TXT")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tabs" },
        _vm._l(_vm.tabs, function(tab) {
          return _c(
            "woot-button",
            {
              key: tab.id,
              class: { active_btn: _vm.activeTab === tab.id },
              attrs: {
                "color-scheme": "primary",
                "class-names": "tab_btn",
                variant: _vm.activeTab === tab.id ? "smooth" : "clear"
              },
              on: {
                click: function($event) {
                  return _vm.setActiveTab(tab.id)
                }
              }
            },
            [_vm._v("\n        " + _vm._s(tab.label) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "search-wrap" },
          [
            _c("fluent-icon", {
              staticClass: "search-icon msg_search_icon",
              attrs: { icon: "search" }
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "contact-search",
              attrs: {
                type: "text",
                placeholder: _vm.$t("MESSAGE_MGMT.SEARCH_PLACEHOLDER")
              },
              domProps: { value: _vm.searchQuery },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onSearchSubmit($event)
                },
                input: _vm.onInputSearch
              }
            }),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                staticClass: "clear",
                attrs: {
                  "is-loading": false,
                  "class-names": _vm.searchButtonClass
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("CONTACTS_PAGE.SEARCH_BUTTON")) +
                    "\n        "
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("MessageList", {
        attrs: {
          onShowTemplateModal: _vm.onShowTemplateModal,
          searchKey: _vm.searchQuery,
          cannType: _vm.activeTab,
          onShowAddCannModal: _vm.openAddPopup
        }
      }),
      _vm._v(" "),
      _c("CreateTemplateMessageModal", {
        attrs: {
          onClose: _vm.onCloseTemplateModal,
          show: _vm.addTemplateMsgShow,
          onCreateSuccess: _vm.onSuccessTemplateCreate
        }
      }),
      _vm._v(" "),
      _c("ReviewNotifyModal", {
        attrs: {
          show: _vm.reviewNotifyShow,
          onClose: _vm.onCloseReviewNotifyModal,
          onOkay: _vm.onReviewNotifyOkay
        }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [_c("add-canned", { attrs: { "on-close": _vm.hideAddPopup } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }