var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "div",
        { staticClass: "event_wrapper" },
        [
          _c(
            "modal",
            {
              attrs: {
                show: _vm.isOpen,
                "on-close": _vm.closeModal,
                closeBtnClass: "event_close_btn",
                modalMainContainerClass: ""
              },
              on: {
                "update:show": function($event) {
                  _vm.isOpen = $event
                }
              }
            },
            [
              _c("div", { staticClass: "event" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.contest.landing_page_url,
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.contest.poster_url, alt: "" }
                    })
                  ]
                ),
                _vm._v(" "),
                !_vm.contest.account_joined
                  ? _c("div", [
                      _c(
                        "label",
                        { staticClass: "term_condition", attrs: { for: "" } },
                        [
                          _vm.isJoining
                            ? _c("spinner", { attrs: { size: "small" } })
                            : _c("input", {
                                attrs: { type: "checkbox", name: "", id: "" },
                                on: { change: _vm.onCheckAccept }
                              }),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("CONTEST_SETTING.I_JOIN")) +
                                "\n                        "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.contest.term_url,
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("CONTEST_SETTING.TERMS_CONDITION")
                                  )
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }