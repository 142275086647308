import { render, staticRenderFns } from "./AddTemplate.vue?vue&type=template&id=77b3e4b4&"
import script from "./AddTemplate.vue?vue&type=script&lang=js&"
export * from "./AddTemplate.vue?vue&type=script&lang=js&"
import style0 from "./AddTemplate.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77b3e4b4')) {
      api.createRecord('77b3e4b4', component.options)
    } else {
      api.reload('77b3e4b4', component.options)
    }
    module.hot.accept("./AddTemplate.vue?vue&type=template&id=77b3e4b4&", function () {
      api.rerender('77b3e4b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/canned/AddTemplate.vue"
export default component.exports