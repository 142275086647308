var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: { "header-title": "Refund Confirmation" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "modal_body" }, [
        _c("div", { staticClass: "item" }, [
          _c("label", [_vm._v("Order Amount")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.order.grand_total) + " " + _vm._s(_vm.order.currency)
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("label", [_c("span", [_vm._v("Paid Amount")])]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.order.paid_total) + " " + _vm._s(_vm.order.currency)
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item refund_field" }, [
          _c("label", [_vm._v("Refund Amount")]),
          _vm._v(" "),
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.refund_amount,
                  expression: "refund_amount"
                }
              ],
              class: { error: _vm.$v.refund_amount.$error },
              attrs: { type: "text", placeholder: "Refund amount eg. 10.50" },
              domProps: { value: _vm.refund_amount },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.refund_amount = $event.target.value
                  },
                  _vm.onRefundInput
                ]
              }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "error_msg" }, [
              _vm._v(
                _vm._s(
                  _vm.$v.refund_amount.$error
                    ? "Only positive amount is required"
                    : ""
                )
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-submit-button", {
                attrs: {
                  "button-text": "Confirm",
                  loading: _vm.isRefunding,
                  disabled: _vm.isRefunding
                },
                on: { click: _vm.onRefundAmount }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button clear",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT")) +
                      "\n                "
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }