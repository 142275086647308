import { render, staticRenderFns } from "./AddCanned.vue?vue&type=template&id=3ae21469&"
import script from "./AddCanned.vue?vue&type=script&lang=js&"
export * from "./AddCanned.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ae21469')) {
      api.createRecord('3ae21469', component.options)
    } else {
      api.reload('3ae21469', component.options)
    }
    module.hot.accept("./AddCanned.vue?vue&type=template&id=3ae21469&", function () {
      api.rerender('3ae21469', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/canned/AddCanned.vue"
export default component.exports